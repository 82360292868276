<template>
  <v-container fluid class="px-15" style="margin-bottom: 100px;">
    <v-row justify="space-between" class="px-3 mt-4" align="center">
      <h1 class="secondary--text">Modelos de Layout</h1>
      <v-btn class="float-right" color="primary" @click="creationNewLayoutModel()" min-height="52">
        <v-icon style="font-size: 17px;" class="mr-2">fa-plus</v-icon>Criar Modelo de Layout
      </v-btn>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="layoutModels"
          :page.sync="page"
          :items-per-page="10"
          hide-default-footer
          :loading="loadingTable"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:no-data>
            <span>Nenhum item foi encontrado.</span>
          </template>
          <template v-slot:[`item.layoutType`]="{ item }">
            <span>{{ item.layoutType === 'NONE' ? 'Nenhum' : item.layoutType === 'POSITIONAL' ? 'Posicional' : item.layoutType === 'DELIMITED' ? 'Delimitado' : null}}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <div class="menu">
                <v-list dense>
                  <v-list-item-group color="primary">
                    <v-list-item @click="editLayoutModel(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="copyLayoutModel(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-content-copy</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Copiar configuração</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="deleteLayoutModel(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Excluir</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>
            </v-menu>
          </template>
        </v-data-table>
        <div class="float-right pt-2">
          <v-pagination
            color="textPrimary"
            v-model="page"
            :length="totalPages"
            :total-visible="9"
            :disabled="loadingTable"
            @input="loadModels()"
          />
        </div>
      </v-col>

      <v-col cols="12" align="end">
        <v-footer>
          <v-row class="d-flex pt-6 justify-end">
            <v-btn
              x-large
              outlined
              color="textPrimary"
              width="250px"
              @click="onClickCancel()"
            >
              Voltar
            </v-btn>
          </v-row>
        </v-footer>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="120">
        <div>Carregando...</div>
      </v-progress-circular>
    </v-overlay>

    <ExclusionDynamicModal :show="dialog.show" :loadingConfirm="loadingConfirm" @close="closeDeleteLayoutModel" @confirm="confirmDeleteLayoutModel">
      <template slot="header"> Excluir Modelo de Layout </template>
      <template slot="content">
        Tem certeza que deseja excluir este modelo de layout?
      </template>
    </ExclusionDynamicModal>

  </v-container>
</template>

<script>
import ExclusionDynamicModal from '@/components/ExclusionDynamicModal/ExclusionDynamicModal.vue';
import LayoutService from '@/services-http/sdi/LayoutService';
import LayoutColumnService from '@/services-http/sdi/LayoutColumnService';
import GenericFunctionsMixin from '@/shared/mixins/genericFunctions/genericFunctionsMixin';

export default ({
  components: { ExclusionDynamicModal },

  data: () => ({
    layoutModels: [],
    loadingTable: false,
    loadingConfirm: false,
    overlay: false,
    page: 1,
    totalPages: 0,
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
    dialog: {
      show: false,
      layoutId: null,
    },
    layout: {},

    headers: [
      { text: 'Modelos Disponíveis', align: 'start', value: 'name' },
      { text: 'Tipo de Arquivo', value: 'fileType' },
      { text: 'Tipo de Layout', value: 'layoutType' },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
  }),

  mixins: [
    GenericFunctionsMixin,
  ],

  mounted() {
    this.clearSessionStorage();
    this.loadModels();
  },

  methods: {
    loadModels() {
      this.loadingTable = true;
      this.layoutService.FindAllByFilter({ isModel: true, page: this.page - 1, size: 10 }).then((response) => {
        if (response && response.data.content.length > 0) {
          this.layoutModels = response.data.content;
          this.layoutModels = this.layoutModels.sort((a, b) => (a.id > b.id ? -1 : 1));
          this.totalPages = response.data.totalPages;
        }
        this.loadingTable = false;
      }).catch(() => {
        this.loadingTable = false;
      });
    },
    creationNewLayoutModel() {
      this.$router.push({ name: 'InitialConfigs' });
    },
    async editLayoutModel(item) {
      if (item) {
        this.overlay = true;
        this.layout = item;
        const query = `?id_layout=${item.id}`;
        this.layoutColumnService.FindAllByFilter(query).then((response) => {
          if (response && response.data.content.length > 0) {
            this.layout.columns = response.data.content[0].columns;
            sessionStorage.setItem('layout', JSON.stringify(this.layout));
            this.$router.push({ name: 'ColumnEditConfigs' });
          }
          this.overlay = false;
        }).catch(() => {
          this.overlay = false;
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao localizar as colunas do layout');
        });
      }
    },
    copyLayoutModel(item) {
  if (item) {
    this.overlay = true;
    this.layout = item;
    const query = `?id_layout=${item.id}`;
    
    this.layoutColumnService.FindAllByFilter(query).then((response) => {
      if (response && response.data.content.length > 0) {
        this.layout.columns = response.data.content[0].columns;
        
        this.layout.name = '';
        sessionStorage.setItem('layout', JSON.stringify(this.layout));
        this.$router.push({ name: 'ColumnCopyConfigs' });
      } else {
        this.customizeSnackbarMessage('warning', 'Este modelo de layout não possui colunas configuradas e não pode ser copiado.');
      }
      
      this.overlay = false;
    }).catch(() => {
      this.overlay = false;
      this.customizeSnackbarMessage('error', 'Ocorreu um erro ao localizar as colunas do layout');
    });
  }
},
    deleteLayoutModel(item) {
      this.dialog.show = true;
      this.dialog.layoutId = item.id;
    },
    closeDeleteLayoutModel() {
      this.dialog.show = false;
      this.dialog.layoutId = null;
    },
    confirmDeleteLayoutModel() {
      this.loadingConfirm = true;
      if (this.dialog.layoutId) {
        this.layoutService.Delete(this.dialog.layoutId).then(() => {
          this.layoutModels = [];
          this.closeDeleteLayoutModel();
          this.loadModels();
          this.customizeSnackbarMessage('success', 'Modelo de Layout deletado com sucesso');
          this.loadingConfirm = false;
        }).catch(() => {
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao deletar o modelo de layout');
          this.loadingConfirm = false;
        });
      }
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    onClickCancel() {
      this.$router.push({ name: 'HomeAllMenus' });
    },
  },

  created() {
    this.layoutService = new LayoutService();
    this.layoutColumnService = new LayoutColumnService();
  },
});
</script>
